import { Dispatch } from "redux";
import h from "../helpers";
import { IProvider, ISetExternalProviders } from "../types/stores/login";
import { SET_EXTERNAL_PROVIDERS } from "../reducers/login";

export const setProviders = (providers: Array<IProvider>): ISetExternalProviders => ({
    type: SET_EXTERNAL_PROVIDERS,
    providers,
});

export const getLoginProviders =
    () =>
    (dispatch: Dispatch): void => {
        const url = "../../../../Account/LoginProviders";
        fetch(url, { credentials: "same-origin", method: "GET" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(response => {
                const data = response as unknown as Array<IProvider>;
                dispatch(setProviders(data));
            })
            .catch(error => {
                h.error("Error getting Log in Providers.", error);
            });
    };

export const externalLogin = (authenticationType: string): void => {
    let form = document.createElement("form");
    let provider = <HTMLInputElement>document.createElement("input");
    let forgeryToken = <HTMLInputElement>document.createElement("input");
    form.method = "POST";
    form.action = "../../../../Account/ExternalLogin";
    provider.value = authenticationType;
    provider.name = "provider";
    forgeryToken.value = (<HTMLInputElement>document.getElementsByName("__RequestVerificationToken")[0]).value;
    forgeryToken.name = "__RequestVerificationToken";

    form.appendChild(provider);
    form.appendChild(forgeryToken);
    document.body.appendChild(form);
    form.submit();
};

export const termsAndConditionsResponse = (accepted: boolean, email: string): void => {
    // Get the Anti-forgery Token to pass in the headers
    let token = "";
    if (document.getElementsByName("__RequestVerificationToken").length > 0) {
        let element = document.getElementsByName("__RequestVerificationToken")[0];
        if (element instanceof HTMLInputElement) {
            token = element.value;
        }
    }

    fetch("../Account/TermsAndConditions", {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            __RequestVerificationToken: token,
        },
        body: JSON.stringify({
            Accepted: accepted,
            Email: email,
            ReturnUrl: null,
        }),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(() => {
            window.location.href = "/";
        })
        .catch(error => {
            h.error("Error", error);
        });
};
