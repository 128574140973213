import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoginProviders, externalLogin } from "../../actions/loginActions";
import Tooltip from "@material-ui/core/Tooltip";
import { IAppState } from "../../types/stores";

const ExternalLoginsListPartial: React.FC = () => {
    const dispatch = useDispatch();

    const providers = useSelector((state: IAppState) => state.login.providers);

    useEffect(() => {
        dispatch(getLoginProviders());
    }, [dispatch]);

    let externalLoginOption: Array<JSX.Element> = [];

    if (providers.length != 0) {
        for (let i = 0; i < providers.length; i++) {
            if (providers[i].Properties) {
                if (providers[i].Properties!.AuthenticationType == "OpenIdConnect") {
                    externalLoginOption.push(
                        <Tooltip key={"tooltip-" + i} title={"Log in using your Experian account"}>
                            <button
                                onClick={() => externalLogin(providers[i].Properties!.AuthenticationType)}
                                className={"external-login-button"}
                            >
                                Experian SSO
                            </button>
                        </Tooltip>
                    );
                } else if (providers[i].Properties!.AuthenticationType == "OpenIdConnectB2B") {
                    externalLoginOption.push(
                        <Tooltip key={"tooltip-" + i} title={"Log in using your Client account"}>
                            <button
                                onClick={() => externalLogin(providers[i].Properties!.AuthenticationType)}
                                className={"external-login-button"}
                            >
                                Client SSO
                            </button>
                        </Tooltip>
                    );
                } else {
                    externalLoginOption.push(
                        <button
                            key={"button-" + i}
                            onClick={() => externalLogin(providers[i].Properties!.AuthenticationType)}
                            className={"external-login-button"}
                        >
                            {providers[i].Properties!.AuthenticationType}
                        </button>
                    );
                }
            }
        }
    }

    return externalLoginOption.length > 0 ? (
        <div className="external-logins-container">
            <p>Or use another service to log in</p>
            {externalLoginOption}
        </div>
    ) : null;
};

export default ExternalLoginsListPartial;
