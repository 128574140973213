import { ForgotPasswordActions, IForgotPasswordStore } from "../types/stores/forgotPassword";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_COMPLETED = "FORGOT_PASSWORD_COMPLETED";

function forgotPassword(
    state: IForgotPasswordStore = {
        submit: false,
        success: false,
        passwordErrors: [],
    },
    action: ForgotPasswordActions
): IForgotPasswordStore {
    switch (action.type) {
        case FORGOT_PASSWORD:
            return Object.assign({}, state, {
                submit: action.submit,
                passwordErrors: [],
            });
        case FORGOT_PASSWORD_COMPLETED: {
            return Object.assign({}, state, {
                submit: false,
                success: action.success,
                passwordErrors: action.errors,
            });
        }
        default:
            return state;
    }
}

export default forgotPassword;
