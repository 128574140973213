/**
 * Gets the browser info based on user agent.
 * @returns {any} - Returns browser name and version.
 */
export const getBrowser = () => {
    let userAgent = navigator.userAgent;
    let temp,
        browser = null;
    let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (match && match[1]) {
        // Check for IE browser engine
        if (match[1].includes("trident")) {
            temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return { name: "IE", version: temp[1] || "" };

            // Check for Opera/Edge
        } else if (match[1].includes("Chrome")) {
            temp = userAgent.match(/\bOPR|Edge|Edg\/(\d+)/);

            if (temp != null) {
                if (temp[0].includes("Edge") || temp[0].includes("Edg")) {
                    return { name: "Edge", version: temp[1] };
                } else if (temp[0].includes("OPR")) {
                    return { name: "Opera", version: temp[1] };
                } else {
                    return { name: temp[0], version: temp[1] };
                }
            }
        }

        browser = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion];
    }

    return {
        name: browser && browser[0] ? browser[0] : "",
        version: browser && browser[1] ? browser[1] : "",
    };
};

/**
 * An array of supported browsers
 */
export const supportedBrowsers = ["Chrome"];
