import React from "react";
import { render } from "react-dom";

import { Provider } from "react-redux";
import store from "../store";

import Login from "../components/session/Login";

const LoginApp = (
    <Provider store={store}>
        <Login />
    </Provider>
);

render(LoginApp, document.getElementById("root"));
