import { IResetPasswordStore, ResetPasswordActions } from "../types/stores/resetPassword";

export const RESETTING_PASSWORD = "RESETTING_PASSWORD";
export const RESETTING_PASSWORD_SUCCESS = "RESETTING_PASSWORD_SUCCESS";

function resetPassword(
    state: IResetPasswordStore = {
        passwordIsSaving: false,
        passwordErrors: [],
        success: false,
    },
    action: ResetPasswordActions
): IResetPasswordStore {
    switch (action.type) {
        case RESETTING_PASSWORD:
            return Object.assign({}, state, {
                passwordIsSaving: action.isSaving,
            });
        case RESETTING_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                passwordIsSaving: false,
                success: action.success,
                passwordErrors: action.errors,
            });
        default:
            return state;
    }
}

export default resetPassword;
