import { ILoginState, LoginActions } from "../types/stores/login";
export const SET_EXTERNAL_PROVIDERS = "SET_EXTERNAL_PROVIDERS";

function loadingStates(
    state: ILoginState = {
        providers: [],
    },
    action: LoginActions
): ILoginState {
    switch (action.type) {
        case SET_EXTERNAL_PROVIDERS:
            return Object.assign({}, state, {
                providers: action.providers,
            });

        default:
            return state;
    }
}
export default loadingStates;
