import React, { useEffect, useState, createRef } from "react";

type Props = {
    type: string;
    text?: string;
};

/** TODO: probably extend this to support a collection of snacks that stack vertically if we need it */
const Snackbar: React.FC<Props> = ({ type, text = "" }: Props) => {
    const snackbarRef = createRef<HTMLDivElement>();
    //State
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showDismiss, setShowDismiss] = useState(false);
    const [textValue, setText] = useState("");

    /**
     * Depending on the type of snack bar we may have specific text and settings/options.
     * In a default type of snackbar (with no type prop passed in, just text), we timeout instead like a toast would.
     */
    useEffect(() => {
        switch (type) {
            case "browser":
                setShowSnackbar(
                    Boolean(
                        sessionStorage.getItem("browser-snackbar")
                            ? sessionStorage.getItem("browser-snackbar") == "true"
                            : true
                    )
                );
                setShowDismiss(true);
                setText("You are using an unsupported browser. For optimal experience, please use Chrome.");
                break;
            default:
                setShowSnackbar(true);
                setText(text);

                setTimeout(() => {
                    setShowSnackbar(false);
                }, 5000);
                break;
        }
    }, [type, text]);

    /**
     * We dismiss and set one-off states via local storage(?) based on snackbar type.
     */
    const dismiss = () => {
        switch (type) {
            case "browser":
                setShowSnackbar(false);
                setText("You are using an unsupported browser. For optimal experience, please use Chrome.");
                sessionStorage.setItem("browser-snackbar", "false");
                break;
            default:
                break;
        }
    };

    return showSnackbar ? (
        <div className="snackbar-container">
            <div ref={snackbarRef} className="snackbar show">
                <span>{textValue}</span>
                {showDismiss && (
                    <button className="dismiss-btn" onClick={() => dismiss()}>
                        Dismiss
                    </button>
                )}
            </div>
        </div>
    ) : null;
};

export default Snackbar;
